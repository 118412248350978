<template>
  <v-container fluid>
    <h4 class="mb-5">{{$_strings.approval.INBOX}}</h4>
    <v-data-table
      :loading="isLoading"
      :headers="displayedHeader"
      :items="itemsTable"
      sort-by="date"
      class="elevation-1 table"
      item-key="id"
      :options.sync="pagination"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      :server-items-length="totalData"
      :item-class="itemRowBackground"
      @dblclick:row="dblClick"
    >
      <template v-slot:[`item.number`]="{ index }">
        <span>{{ getNumber(index) }}</span>
      </template>
      <template v-slot:[`item.process`]="{ item }">
        <a
          @click="handleAction(item, item.defWorkflowId, item.id)"
        >
          {{item.process}}
        </a>
      </template>
      <template v-slot:[`item.requestDate`]="{ item }">
        <span>{{ dateFormat(item.requestDate) }}</span>
        <p class="grey--text text--darken-2">{{ timeFormat(item.requestDate) }}</p>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ formatStatus(item.status) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!-- ACCEPTED BUTTON -->
        <v-tooltip bottom>
          <template
            v-slot:activator="{ on, attrs }"
            v-if="(item.status !== 'REVISE' && showButtonsApproveActionByDefworkflow.includes(item.defWorkflowId) || item.status === 'SUBMIT')">
            <v-btn
              @click="handleAction(item, item.defWorkflowId, item.id, { tab: 2 }, { approvalAction: 1 })"
              color="primary"
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-file-document-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.approval.ACCEPTED}}</span>
        </v-tooltip>
        <!-- REVISE BUTTON -->
        <v-tooltip bottom>
          <template
            v-slot:activator="{ on, attrs }"
            v-if="(item.status !== 'REVISE' && showButtonsApproveActionByDefworkflow.includes(item.defWorkflowId) || item.status === 'SUBMIT')"
          >
            <v-btn
              @click="handleAction(item, item.defWorkflowId, item.id, { tab: 2 }, { approvalAction: 2 })"
              color="primary"
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-file-document-edit
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.approval.REVISI}}</span>
        </v-tooltip>
        <!-- REJECT BUTTON -->
        <v-tooltip bottom>
          <template
            v-slot:activator="{ on, attrs }"
            v-if="(item.status !== 'REVISE' && showButtonsApproveActionByDefworkflow.includes(item.defWorkflowId) || item.status === 'SUBMIT')"
          >
            <v-btn
              @click="handleAction(item, item.defWorkflowId, item.id, { tab: 2 }, { approvalAction: 3})"
              color="primary"
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-close-circle
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.approval.REJECT}}</span>
        </v-tooltip>
        <!-- VIEW DETAIL BUTTON -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="handleAction(item, item.defWorkflowId, item.id)"
              color="primary"
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon v-if="item.status === 'REVISE' && !showButtonsApproveActionByDefworkflow.includes(item.defWorkflowId)">
                mdi-pencil
              </v-icon>
              <v-icon v-else>
                mdi-file-find
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{item.status === 'REVISE' && !showButtonsApproveActionByDefworkflow.includes(item.defWorkflowId) ?
              $_strings.common.EDIT :
              $_strings.common.VIEW_DETAIL
            }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import {
  dateFormat,
  timeFormat,
  handleSortBy,
  handlerPagination,
  defaultPagination,
  statusActionWorkflow,
} from '@/helper/commonHelpers';

export default {
  data() {
    return {
      isCreated: false,
      isLoading: false,
      totalData: 0,
      headers: [
        {
          text: this.$_strings.common.NUMBER,
          value: 'number',
          sortable: false,
        },
        {
          text: this.$_strings.common.DATE,
          value: 'requestDate',
          width: '12vw',
        },
        {
          text: this.$_strings.common.PROCESS,
          value: 'process',
          width: '15vw',
        },
        {
          text: this.$_strings.contract.header.COMPANY_NAME,
          value: 'companyName',
          width: '15vw',
        },
        {
          text: this.$_strings.approval.CREATED_AT,
          value: 'requester',
          width: '15vw',
        },
        {
          text: this.$_strings.common.NOTE,
          value: 'notes',
          sortable: false,
          width: '12vw',
        },
        {
          text: this.$_strings.approval.STATUS_APPROVAL,
          value: 'status',
          width: '18vw',
        },
        {
          text: this.$_strings.common.ACTION,
          value: 'actions',
          width: '18vw',
          sortable: false,
        },
      ],
      pagination: defaultPagination(),
      itemsTable: [],
      // show all button action =>  register shipper, register transporter, dokumen pendukung
      showButtonsApproveActionByDefworkflow: [1, 2, 15],
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchData();
      },
      deep: true,
    },
    inboxUnread() {
      if (this.isCreated) this.fetchData();
    },
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary',
        cellClass: 'clickable',
      }));
    },
    inboxUnread() {
      return this.$store.getters['user/inboxUnread'];
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    async getInboxUnread() {
      const res = await this.$_services.approval.getInboxUnread();
      this.$store.dispatch('user/setInboxUnread', res.data);
    },
    dblClick(event, { item }) {
      this.handleAction(item, item.defWorkflowId, item.id);
    },
    itemRowBackground(item) {
      return item.isRead ? null : 'font-weight-bold';
    },
    async setIsRead(item) {
      const { id, isRead } = item;
      if (isRead) {
        return;
      }
      try {
        this.$root.$loading.show();
        await this.$_services.approval.setIsRead(id);

        const updateCountInboxUnread = Number(this.$store.getters['user/inboxUnread']) - 1;
        this.$store.dispatch('user/setInboxUnread', updateCountInboxUnread);

        return true;
      } finally {
        this.$root.$loading.hide();
      }
    },
    async fetchData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ defaultValue: 'requestDate,DESC', sortBy, sortDesc }),
      };
      try {
        this.isLoading = true;
        const result = await this.$_services.approval.getListInbox(filters);
        this.itemsTable = result.data.contents;
        this.totalData = result.data.totalData;
        if (!this.isCreated) this.getInboxUnread();
        this.isCreated = true;
      } finally {
        this.isLoading = false;
      }
    },
    async handleAction(item, defWorkflow, id, { tab = 0 } = {}, { approvalAction = null } = {}) {
      try {
        await this.setIsRead(item);
        if (defWorkflow === 15 && tab === 0) tab = 1; // TAB 1 IS DOCUMENT SUPPORT
        const query = {
          tab,
        };
        if (approvalAction) query.approvalAction = approvalAction;
        this.$router.push({
          name: 'skeleton-loaders',
          params: {
            page: 'inbox',
            id,
            defWorkflow,
          },
          query,
        });
      } catch {
        return false;
      }
    },
    getNumber(index) {
      const x = this.pagination.page * this.pagination.itemsPerPage;
      return x - this.pagination.itemsPerPage + index + 1;
    },
    formatStatus(status) {
      return statusActionWorkflow(status);
    },
  },
};
</script>
